/* You can add global styles to this file, and also import other style files */
body {
	background-color: #040853;
	font-family: "Fira Sans";
}

/* montserrat-300 - latin-ext_latin */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 300;
	src: local(""),
		url("assets/fonts/montserrat-v15-latin-ext_latin-300.woff2")
			format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("assets/fonts/montserrat-v15-latin-ext_latin-300.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-regular - latin-ext_latin */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	src: local(""),
		url("assets/fonts/montserrat-v15-latin-ext_latin-regular.woff2")
			format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("assets/fonts/montserrat-v15-latin-ext_latin-regular.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500 - latin-ext_latin */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	src: local(""),
		url("assets/fonts/montserrat-v15-latin-ext_latin-500.woff2")
			format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("assets/fonts/montserrat-v15-latin-ext_latin-500.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700 - latin-ext_latin */
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	src: local(""),
		url("assets/fonts/montserrat-v15-latin-ext_latin-700.woff2")
			format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("assets/fonts/montserrat-v15-latin-ext_latin-700.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* fira-sans-300 - latin-ext_latin */
@font-face {
	font-family: "Fira Sans";
	font-style: normal;
	font-weight: 300;
	src: local(""),
		url("assets/fonts/fira-sans-v10-latin-ext_latin-300.woff2")
			format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("assets/fonts/fira-sans-v10-latin-ext_latin-300.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* fira-sans-300italic - latin-ext_latin */
@font-face {
	font-family: "Fira Sans";
	font-style: italic;
	font-weight: 300;
	src: local(""),
		url("assets/fonts/fira-sans-v10-latin-ext_latin-300italic.woff2")
			format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("assets/fonts/fira-sans-v10-latin-ext_latin-300italic.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* fira-sans-regular - latin-ext_latin */
@font-face {
	font-family: "Fira Sans";
	font-style: normal;
	font-weight: 400;
	src: local(""),
		url("assets/fonts/fira-sans-v10-latin-ext_latin-regular.woff2")
			format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("assets/fonts/fira-sans-v10-latin-ext_latin-regular.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* fira-sans-italic - latin-ext_latin */
@font-face {
	font-family: "Fira Sans";
	font-style: italic;
	font-weight: 400;
	src: local(""),
		url("assets/fonts/fira-sans-v10-latin-ext_latin-italic.woff2")
			format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("assets/fonts/fira-sans-v10-latin-ext_latin-italic.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* fira-sans-500 - latin-ext_latin */
@font-face {
	font-family: "Fira Sans";
	font-style: normal;
	font-weight: 500;
	src: local(""),
		url("assets/fonts/fira-sans-v10-latin-ext_latin-500.woff2")
			format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("assets/fonts/fira-sans-v10-latin-ext_latin-500.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* fira-sans-500italic - latin-ext_latin */
@font-face {
	font-family: "Fira Sans";
	font-style: italic;
	font-weight: 500;
	src: local(""),
		url("assets/fonts/fira-sans-v10-latin-ext_latin-500italic.woff2")
			format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("assets/fonts/fira-sans-v10-latin-ext_latin-500italic.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
